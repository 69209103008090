<template>
  <div class="el-row">
    <div class="el-col-24 el-dialog--center">
      <el-image :src="require(`./assets/splash.dacy.png`)" fit="contain"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Splash",
  props: [],
  components: {
  },
  data () {
    return {
    }
  },
  mounted: function () {
  }
  /*
  computed: {
    var: function () {
    }
  }
  */
  /*
  watch: {
    var: function (val) {
    }
  },
  */
  /*
  methods: {
    functionname() {
    }
}
*/
}
</script>
<style>
body {
  overflow: hidden;
  background-color:#FFF !important;
}
</style>
